/* @font-face {
  font-family: 'KGRedHands';
  src: url(../public/assets/fonts/KGRedHands.ttf);
}

@font-face {
  font-family: 'Champagne';
  src: url(../public/assets/fonts/Champagne\ _\ Limousines.ttf);
} */

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow-x: hidden;
  font-family: "Itim", cursive;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.z-1 {
  z-index: 1;
}

/* .bg-black{
  background: rgb(255 255 255 / 70%) !important;
  backdrop-filter: blur(10px);
}
.fs-14 {
  font-size: 14px !important;
} */

/* Header  */

/* .navbar {

  position: fixed;
  width: 100%;
  z-index: 111;
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #FFFFFF;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #61DFFF;
  transition: all .2s ease-in-out;
}

.navbar-collapse.collapsing {
  transition: none !important;
}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}




@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }
}

@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }
  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 90%;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    border-right: 1px solid #6C6C6C;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 30px;
  }
} */

@media (min-width: 1000px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1160px;
  }
}


/* Banner */

.section-wrapper {
  padding-top: 100px;
  background-color: #C3F5D0;
  border-radius: 20px;
  border: 7px solid #000;
  position: relative;
  margin-top: 10px;
}

.bg-1 {
  background-image: url(../public/assets/bg1.png);
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 120px;
}

.h1 {
  color: #00B439;
  font-size: 100px;
  line-height: 78px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
  margin-bottom: 0;
}

.solana {
  font-size: 32px;
  color: black;
}

.sp-1 {
  background: url(../public/assets/sp1.png);
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 56px 30px 10px 30px;
}

.sp-1 p {
  color: white;
  font-size: 20px;
}

.sp-2 {
  background: url(../public/assets/sp2.png);
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 15px;
  max-width: 340px;
  margin-left: 25px;
  margin-top: -25px;
  text-align: center;
}

.sp-2 h4 {
  color: #00B439;
  font-size: 38px;
  /* text-transform: uppercase; */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin-bottom: 30px;
  line-height: 59px;
}

.sp-ic {
  position: absolute;
  width: 100%;
  max-width: 230px;
  right: -20%;
  top: -65%;
}

.frog {
  position: absolute;
  bottom: 7px;
  left: 19px;
  width: 100%;
  max-width: 340px;
}

.social-icons {
  position: absolute;
  right: 0;
  z-index: 22;
  right: -70px;
  top: -60px;
  display: flex;
  gap: 15px;
}

.social-icons img, .icon {
  transition: all .2s ease-in-out;
}

.social-icons img:hover , .icon:hover{
  transform: translateY(-5px);
  transition: all .2s ease-in-out;
}

.primary-btn {
  background: url(../public/assets/btnBg.png);
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 18px 40px;
  font-size: 30px;
  display: inline-block;
  transition: all .2s ease-in-out;
}

.primary-btn:hover {
  transform: translateY(-5px);
  transition: all .2s ease-in-out;
}

.btn-ic {
  position: absolute;
  left: -40px;
  top: -40px;
  height: 80px;
}

.about-card{
  border: 6px solid #000;
  background: white;
  border-radius: 30px;
  padding: 50px 34px;
  text-align: center;
}
.about-card p{
  margin-bottom: 0;
  font-size: 18px;
}
.about-card h2{
  color: #fff;
  font-size: 56px;
  line-height: 52px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
  margin-bottom: 20px;
}
.smile{
  max-width: 350px;
}

.smile-img{
  position: absolute;
  bottom: 0px;
  right: -45px;
}

.tx-text{
  font-size: 32px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.tx-img{
  max-width: 760px;
  margin: 0 auto;
  margin-top: -50px;
}

.bg-2 {
  background-image: url(../public/assets/bg2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}

.w-600{
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
}

.token{
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.tokenomics {
  background: url(../public/assets/token-bg.png);
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 25px 20px 60px 20px;
  text-align: center;
  color: #00B439;
  font-size: 42px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin-bottom: 30px;
  width: 100%;
  max-width: 500px;
  display: inline-block;
  transition: all .2s ease-in-out;
}
.w-500{
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
}

.token-ic{
  position: absolute;
  right: -12px;
  top: -50px;
  width: 100%;
  max-width: 110px;
}
.tex{
    background: white;
    border: 6px solid black;
    border-radius: 30px;
    padding: 20px;
    font-size: 30px;
    text-align: center;
    max-width: 460px;
    margin-left: auto;
    margin-top: -30px;
}

.address{
  background: black;
  border: 6px solid white;
  border-radius: 30px;
  color: white;
  padding: 20px 10px;
  padding-bottom: 25px;
  font-size: 28px;
  text-align: center;
  max-width: 500px;
  margin-top: 15px;
  line-height: 26px;
  border-bottom-right-radius: 0;
  padding-bottom: 25px;

}

.address span{
  font-size: 16px;
  color: #EF7B77;
}

.supply{
  background: white;
    border: 6px solid black;
    border-radius: 30px;
    padding: 15px;
    font-size: 36px;
    text-align: center;
    max-width: 460px;
    margin-left: auto;
    margin-top: -6px;
    line-height: 30px;
    position: relative;
    padding-top: 20px;
}

.supply span{
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.supply img{
  position: absolute;
  left: -25px;
  top: -25px;
  width: 100%;
  max-width: 52px;
}

.h2{
  text-align: center;
  color: #fff;
  font-size: 56px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
  margin-bottom: 50px;
}

.dot{
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 40px;
  line-height: 40px;
}

.per{
  color: #fff;
  font-size: 34px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  padding-left: 25px;
}

.b-1{
  border: 7px solid #000;
  border-radius: 20px;
  margin-top: 15px;
  background: white;
  padding: 12px;
}

.b-1 h3{
  font-size: 30px;
  margin-top: 20px;
  text-shadow: 2px 1px 0 #fff;
}

.b-1 p{
  margin-bottom: 0;
  font-size: 18px;
  line-height: 22px;
}

.b-1 img{
  height: 100px;
}

.b-1 .section-wrapper{
  background-image: url(../public/assets/line.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.brb-0{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.sub-footer{
  background: black;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  padding-bottom: 16px;
  font-size: 14px;
}

.sub-footer span{
  color: red;
}

.marqeee{
  margin-top: 10px;
  background: black;
  padding: 8px 25px;
  font-size: 20px;
  border-radius: 10px;
  text-transform: uppercase;
}

.m1{
  color: white;
}
.m2{
  color: #77FA4E;
}

.m3{
  color: #EF7B77;
}

.dex{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: -40px;
}
.dex img{
  width: 100%;
  max-width: 220px;
  transition: all .2s ease-in-out;
}
.dex img:hover{
  width: 100%;
  max-width: 220px;
  transform: translateY(-5px);
  transition: all .2s ease-in-out;
}
.burnt{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.burnt a {
  transition: all .2s ease-in-out;
}

.burnt a:hover {
  transform: translateY(-5px);
  transition: all .2s ease-in-out;
}



.burnt img{
  height: 125px;
  width: 100%;
}

@media (max-width: 767px){
  .section-wrapper{
    border-width: 3px;
    padding: 10px;
  }

  .h1{
    font-size: 66px;
    line-height: 60px;
  }
  .sp-1{
    background-image: url(../public/assets/sp-sm.png);
  }
  .sp-2{
    background-image: url(../public/assets/sp-sm-1.png);
  }
  .sp-ic {
    max-width: 185px;
    right: 0%;
    top: -40%;
}
.sp-2 h4 {
  font-size: 32px;
  -webkit-text-stroke-width: 1px;
  line-height: 42px;
}
.social-icons{
  position: static;
  justify-content: center;
  margin-top: 20px;
}
.smile-img {
  position: static;
}
.about-card{
  border-width: 3px;
  padding: 15px;
  border-radius: 20px;

}
.about-card p{
  margin-bottom: 0;
  font-size: 16px;
}
.about-card h2 {
  font-size: 42px;
  line-height: 40px;
  -webkit-text-stroke-width: 2px;
  margin-bottom: 10px;
}
.smile {
  max-width: 230px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.frog {
  position: relative;
  bottom: 0;
  left: -10px;
  width: 100%;
  max-width: 224px;
  z-index: -1;
  margin-top: -40px;
}
.btn-ic {
  position: absolute;
  left: -25px;
  top: -25px;
  height: 60px;
}
.primary-btn {
  padding: 12px 30px;
  font-size: 26px;
}
.social-icons img {
  height: 55px;
}
.solana {
  font-size: 24px;
  line-height: 28px;
}
.tx-text {
  font-size: 28px;
  margin-bottom: 25px;
  padding-top: 50px;
}

.tx-img {
  margin-top: 0;
}

.token{
  padding-top: 60px;
  padding-bottom: 30px;
}
.token-ic {
  max-width: 90px;
}
.address span {
  line-break: anywhere;
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
}
.tokenomics {
  padding: 20px 20px 45px 20px;
  font-size: 30px;
  -webkit-text-stroke-width: 1px;
  margin-bottom: 20px;
}
.tex {
  border: 3px solid black;
  border-radius: 20px;
  padding: 15px;
  font-size: 22px;
  max-width: 270px;
}
.address {
  border: 3px solid white;
  border-radius: 20px;
  padding: 15px;
  padding-bottom: 20px;
  font-size: 22px;
  max-width: 300px;
  border-bottom-right-radius: 0;

}
.supply {
  border: 3px solid black;
  border-radius: 20px;
  padding: 12px;
  font-size: 22px;
  max-width: 280px;
}
.supply span {
  font-size: 18px;
}
.h2 {
  font-size: 40px;
  -webkit-text-stroke-width: 2px;
}
.per {
  font-size: 26px;
}
.dot {
  font-size: 34px;
  line-height: 30px;
}

.b-1 .section-wrapper{
  background-image: url(../public/assets/line-sm.png);
  background-position: 100%;
  background-size: cover;
}

.b-1 {
  border: 3px solid #000;
  padding: 5px;
}

.b-1 img {
  height: 80px;
}
.b-1 h3 {
  font-size: 28px;
}
.brb-0{
  padding-top: 25px;
}
.dex{
  margin-top: -20px;
  flex-direction: column;
  gap: 0;
}
.dex .dex-img{
    margin-left: 50px;
    margin-top: -20px;
}
.dex img{
  max-width: 180px;
}
.sub-footer{
  flex-direction: column;
  gap: 5px;
}
.bg-1{
  padding-top: 25px;
}
.burnt {
  flex-direction: column;
  gap: 20px;
  max-width: 275px;
  margin: 0 auto;
}
.burnt img {
  height: auto;
}
}
